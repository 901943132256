import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";

export const navLinks = [
  {
    id: "",
    title: "",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "PSIRA Verification",
    content:
      "PSIRA verification refers to the process of verifying that a private security service provider, including companies and individuals offering security services, is registered with and authorized by PSIRA to operate legally within South Africa..",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "South African Matric (Grade 12) Verification",
    content:
      "South African Matric (Grade 12) Verification is a process used to validate the authenticity and accuracy of an individual's National Senior Certificate (Matric) results",
  },
  {
    id: "feature-3",
    icon: send,
    title: "South African Tertiary Qualifications (Degrees, Certificates and Diplomas) ",
    content:
      "These qualifications are structured within the National Qualifications Framework (NQF), which is a comprehensive system established to regulate and standardize the levels and quality of education and training ",
  },
];

export const feedback = [

];

export const stats = [
  {
    id: "stats-1",
    title: "Home Affairs – ID Number Match ",
    value: "",
  },
  {
    id: "stats-2",
    title: "Asylum Seeker and Refugee Permit",
    value: "",
  },
  {
    id: "stats-3",
    title: "Vehicle Registration",
    value: "",
  },
  {
    id: "stats-4",
    title: "Home Affairs – ID Number Match ",
    value: "",
  },
  {
    id: "stats-5",
    title: "Asylum Seeker and Refugee Permit",
    value: "",
  },
  {
    id: "stats-6",
    title: "Vehicle Registration",
    value: "",
  },
  {
    id: "stats-7",
    title: "Drivers License and Professional Drivers Permit Checks",
    value: "",
  },
];

export const footerLinks = [
  {
    title: "Contact Us",
    links: [
      {
        name: "Cell: 0760354518",
        link: "",
      },
      {
        name: "Email: profefficiency@gmail.com",
        link: "",
      },
      {
        name: "Email: jimmy.nkuna@profefficiency.com",
        link: "",
      },
    ],
  },
  {
    title: "Head Office",
    links: [
      {
        name: "99 Leask street, Neserhof, Klerksdorp, 2571",
        link: "",
      },
    ],
  },
  {
    title: "Office hours ",
    links: [
      {
        name: "Monday to Friday - 08:00 to 16:00 ",
        link: "",
      },
      {
        name: "Saturday - 09:00 to 13:00",
        link: "",
      },
      {
        name: "Public holidays closed",
        link: "",
      },
    ],
  },
];

export const socialMedia = [

];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];